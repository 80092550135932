<template>
	<div></div>
</template>

<script>
import {getMeituanPromoteUrl} from '@/api/meituan'
import ls from "@/libs/localstore"

export default {
	components: {},
	data() {
		return {};
	},
	computed: {},
	created() {},
	mounted() {
		this._loadData()
	},
	methods: {
		_loadData() {
			let params = {
				 
			}
			getMeituanPromoteUrl(params).then(res => {
				if(res.errCode === 0) {
					window.location.href = res.data.url
				}
			})
		}
	}
};
</script>

<style scoped></style>
